.footer {
    display: flex; flex-direction: row; align-items: center; justify-content: space-around;
    box-shadow:  0 0 16px 4px silver;
    background-color: rgb(212, 212, 212);
    height: 300px;
    padding-inline: 32px;
}

.foot-img {
    height: 40%;
}

.foot-icon {
    transform: scale(2.5);
    color: rgb(56, 56, 56);
}

.foot-icon:hover {
    color: #da0b64;
    transition: all 0.3s ease-in-out;

}

.sign {
    font-size: 10pt; text-align: center;
    padding: 10px;
}

.sign a {
    text-decoration: none;
    color: #d82020;
}
.foot-h {
    font-size: 12.5pt;
    width: 15%;
    text-align: center;
}





/* MEDIA QUERY */
/* MEDIA QUERY */
/* MEDIA QUERY */
/* MEDIA QUERY */
/* MEDIA QUERY */

@media (max-width: 1000px) {
  .footer {
      flex-wrap: wrap;
      gap: 60px;
      padding-bottom: 30px;
  }

  /* 
    .one { grid-area: one;}
    .two { grid-area: two;}
    .three { grid-area: three;}
    .four { grid-area: four;}
    .five { grid-area: five;}
    .six { grid-area: six;}
    .seven { grid-area: seven;}
*/
  
  

    .foot-icon {
        transform: scale(2.2);
    }

    .foot-img {
        height: 25%;
    }
    .foot-h {
        font-size: 11pt;
        max-width: 100px;
        text-align: center;
    }
}

@media (max-width: 550px) {
    .foot-icon {
        transform: scale(1.8); 
    }
    .footer {
        padding-inline: 10%;
        height: 400px; padding-block: 30px; 
    }

    .sign {
        font-size: 9pt; 
    }

    .foot-img {
        height: 22%;
    }
    .foot-h {
        font-size: 10.5pt;
        width: fit-content;
        text-align: center;
    }
}

@media (max-width: 330px) {
    .footer {
        padding-inline: 10%;
    }
    .foot-icon {
        transform: scale(1.4);
    }


    .sign {
        font-size: 7.5pt;
    }

    .foot-img {
        height: 20%;
    }
    .foot-h {
        font-size: 9pt;
        width: fit-content;
        text-align: center;
    }
}