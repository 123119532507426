.intro {
    display: flex; flex-direction: row;
    width: 100%; 
    margin-bottom: 160px; margin-top: 160px;
    border-bottom: #da0b64 20px solid;
    padding-block: 50px;
}

.intro1 {
    flex: 1;     padding-left: 10%;
    display: flex; place-items: center; 

}

.intro2 {
    flex: 1;     padding-inline: 5%;
    display: flex; place-items: center; overflow: hidden;  
    font-size: 30pt;
}



.introimg {
    width: 100%;
    border-radius: 20px;
    box-shadow: 0 0 12px 4px grey;

}


@media (max-width: 1000px) {
    .intro2 {
        font-size: 22pt;
    }
}


@media (max-width: 725px) {
    .intro {
     flex-direction: column; 
    }

    .intro1 {
        padding-inline: 5%;
    }

    .intro2 {
        margin-top: 30px;
    }
}

@media (max-width: 550px) {
    .intro2 {
        font-size: 18pt;
    }
}


@media (max-width: 330px) {
    .intro2 {
        font-size: 14pt;
    }
}