.header {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 90vh;

    box-shadow: gray 0 0 16px 4px;

}

.header-img {
    position: absolute;
    top: 0; left: 0; width: 100%; height: 100%; opacity: 0.9; object-fit: cover;
    user-select: none;
}

.header-h1 {
    position: absolute;
    top: 50%; left: 50%; transform: translate(-50%, -50%);
    font-family: 'Hurricane', cursive;
    background-color: white;
    color: #da0b64;
    padding: 16px 32px;
    font-size: 40pt;
    border-radius: 40px;
    border: #da0b64 solid 8px;
    text-align: center;
    opacity: 0.80;
    box-shadow: #da0b64 0 0 10px 2px;

}

.header-ul {
    display: flex;
    flex-direction: row; justify-content: stretch;
    position: absolute;
    bottom: 0; left: 0; width: 100%; height: 30px;
    text-align: center;
   
}

.header-li {
    width: 25%; font-weight: bolder;
}

.head-col-1 {
    background-color: rgb(119, 119, 255);  color: white;     flex: 1;

}
.head-col-2 {
    background-color: orangered;  color: white;    flex: 1;

}
.head-col-3 {
    background-color: darkcyan; color: white;    flex: 1;

}
.head-col-4 {
    background-color: yellow;     flex: 1;

}





/* //////////////////////////////////////////////////////////////////////// */
/* NAVIGATION */
/* //////////////////////////////////////////////////////////////////////// */

.navigation {
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 100px;
    background: linear-gradient(rgb(255, 255, 255), rgba(255, 255, 255, 0.5));
    box-shadow: 0 0 12px 4px silver;

    display: flex; flex-direction: row; justify-content: space-between;

}

.nav-img {
    height: 100%;
    font-family: 'Hurricane', cursive;
color: #da0b64;
width: fit-content; 
font-size: 60pt;
margin: auto 16px;
user-select: none;
}

.nav-bar {
    display: flex; flex-direction: row; justify-content: space-around; align-items: center;
    user-select: none;
}

.nav-link, .nav-link p {
    position: relative;
    text-decoration: none;
    color: black;
    margin-right: 32px;
    font-weight: bold;
    transition: all 0.3s ease-in-out;

}

.nav-link p::after {
    content: "";
    position: absolute;
    bottom: -8px; left: 0; transform: scale(0); height: 5px; width: 100%; background-color: #da0b64;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
transform-origin: 0;

}

.nav-link:hover p {
    color: #da0b64; 
}

.nav-link:hover.nav-link p::after {
    transform: scale(1);
}


.navigation2 {
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 80px;
    background: linear-gradient(rgb(255, 255, 255), rgba(255, 255, 255, 0.5));
    box-shadow: 0 0 12px 4px silver;

    display: flex; flex-direction: row; justify-content: space-between;
    display: none;
}

.nav-img2 {
    height: 100%;
    font-family: 'Hurricane', cursive;
color: #da0b64;
width: fit-content; 
font-size: 45pt;
margin: auto 16px;
user-select: none;
}


.coloured{
    background-color: #da0b64; border-radius: 20px; 
    padding-left: 32px; box-shadow: #da0b64 0 0 8px 2px;
}

.coloured p {
    color: white;
}

.coloured:hover {
    background-color: white;
}





/* //////////////////////////////////////////////////////////////////////// */
/* MEDIA QUERY */
/* //////////////////////////////////////////////////////////////////////// */


@media (max-width: 1000px) {
    .nav-link, .nav-link p {
      
        margin-right: 16px;
    }

    .coloured {
        padding-right: 16px;
    }
}




@media (max-width: 725px) {
    .navigation {
        display: none;
    }
    .navigation2 {
        display: flex;
        position: fixed;
    }

    .rotation {
        color: black;  }

    .switcher {
        color: #da0b64; transform: scale(1.5) rotate(0deg);
        margin-right: 7%; margin-block: auto;
        cursor: pointer;
        border: #da0b64 solid 2px;
        border-radius: 50%; padding: 2px;

    }

    .header-h1 {
        padding: 16px 32px;
        font-size: 32pt;
        width: 65%;
    }

    .showed {
        position: absolute;
        top: 80px; left: 0; width: 100%;
        background-color: #da0b64; 
        display: flex; flex-direction: column; place-items: center;
        gap: 35px;
        opacity: 0.95;
        box-shadow: 0 0 12px 4px black;

        position: fixed;
        z-index: 2;

    }

    .hidden {
       display: none;
    }

    .add {
        position: relative;
        text-decoration: none;
        color: white;
        margin-right: 32px;
        font-weight: bold;
        transition: all 0.3s ease-in-out;    }

       
}






@media (max-width: 550px) {
   .navigation2 {
       height: 75px;
   }
.showed {
    top: 75px;
}

    .header-h1 {
        padding: 8px 16px;
        font-size: 28pt;
        width: 65%;
    }

    .head-col-1, .head-col-2, .head-col-3, .head-col-4 {
        font-size: 10pt
    }

    .header-ul {
        height: 35px;
        text-align: center;
    }

 
 
}


@media (max-width: 330px) {
  
    .header-h1 {
        padding: 5px 10px;
        font-size: 22pt;
        width: 80%;
    }

   

    .head-col-1, .head-col-2, .head-col-3, .head-col-4 {
        font-size: 9pt
    }
}


  

