.contact {
    display: flex;
    flex-direction: column;
}



.content-info {
    margin-inline: 10%;
}

.content-info h1 {
    color: #da0b64;
    margin-bottom: 30px;
}

.organise-p, .organise-li, .features {
    margin-bottom: 120px;
}

.organise-p {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 50px;
}
.organise-p p {
    background-color: yellow;
    color: black; padding: 10%;
    box-shadow: 0 0 10px 3px silver;
    display: flex; 
    font-size: 10pt; text-align: center;
}

.organise-p p b {
    margin-right: 6px;
}

.organise-li {
    display: flex; flex-direction: row; gap: 30px;
    color: rgb(73, 73, 73);
    font-size: 10pt;
}

.features {
    font-weight: bold;
    display: flex; flex-direction: row; gap: 30px;
    font-size: 10pt;

}

.feature1 {
    flex: 1;
    background-color: yellow;     box-shadow: 0 0 10px 3px silver;

    padding: 20px;
}

.feature2 {
    flex: 1;
    background-color: yellow;     box-shadow: 0 0 10px 3px silver;

    padding: 20px;


}





.purple-box {
    padding-inline: 10%;
    padding-block: 40px;
    background-color: rgb(61, 61, 61);
    color: white
}
.black-box {
    padding-inline: 10%;
    padding-block: 40px;
    background-color: #da0b64;
    color: white
}

.purple-box h1 {
    margin-bottom: 50px;
}
.purple-box p, .black-box p {
    font-size: 10pt;
}

.down {
    margin-top: 80px;
}

.last-txt {
    line-height: 1.5;
    color: yellow;
    font-size: 11pt;
}





@media (max-width: 925px) {
    .organise-p {
        grid-template-columns: 1fr 1fr;
    }
}


@media (max-width: 550px) {
    .organise-p p {
        font-size: 8.5pt;
    }
}

@media (max-width: 330px) {
    .content-info h1, .purple-box h1 {
       font-size: 18pt;
    }

    .organise-p {
        grid-template-columns: 1fr;
    }

    .features {
        flex-direction: column;
    }

    .organise-li {
        font-size: 9pt;
    }

    .last-txt {
        font-size: 10pt;
    }

}