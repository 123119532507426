/* 
#da0b64
*/

.contact {
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
}

.google-maps {
    width: 100%; 
    margin-bottom: 160px;
    box-shadow: 0 0 16px 5px grey;
}

.cont-middle {
    display: flex;
    flex-direction: row;
    padding-inline: 10%;
    margin-bottom: 80px;
    gap: 50px;
    justify-content: center; align-items: center;
}


.cont-form {
    flex: 1; width: 100%;
}

.form {
    width: 100%; 
}

.form input {
    width: 85%;
    margin-bottom: 30px;
    color: #da0b64;
    border-inline: 0; outline: 0;
    border-block: silver 2px solid;
    padding: 8px;

}

.form textarea {
    max-width: 85%; min-width: 85%;     margin-bottom: 30px;
    height: 120px;
    padding: 8px;


}

.form h5 {
    color: #da0b64;
}

.form button {
    color: white; background-color: #da0b64; border: 0; padding: 16px 32px; border-radius: 20px;
    transition: all 0.3s ease-in-out;
}

.form button:hover {
    opacity: 0.7;
}

.cont-data {
    flex: 1;
    overflow: hidden;
    display: flex; flex-direction: column;
}






@media(max-width: 825px){
    .cont-middle {
        display: flex;
        flex-direction: column;
        gap: 100px;
    }


}


@media (max-width: 550px) {
    .cont-middle h1 {
        font-size: 22pt;
}

.cont-middle h3 {
    font-size: 12pt;
}

.cont-middle p {
    font-size: 10.5pt;
}
}



@media (max-width: 330px) {

    .form input {
        width: 95%;
    }
    
    .form textarea {
        max-width: 95%; min-width: 95%;
    }
}