.reviews {
    background-color: rgb(212, 212, 212); color: white;
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: flex-start;
    padding-inline: 10%; padding-block: 80px;
}

.rev-link {
    text-decoration: none;
    background-color: #da0b64;
    color: white;
    box-shadow: #da0b64 0 0 8px 2px;

    width: 30%;
    padding: 16px 32px;
    margin-top: 30px;
    text-align: center;
    border-radius: 30px;

    user-select: none;
    transition: all 0.3s ease-in-out;

}

.rev-link:hover {
    opacity: 0.75;
}

.rev-h1 {
    color: #da0b64
}

.rev-scroll {
    height: 160px; width: 100%; background-color: silver;
    overflow: hidden;
    display: flex; flex-direction: row; gap: 8px;
    border-radius: 10px;
    box-shadow: grey 0 0 12px 4px;
}
.rev-img {
    height: 100%;
     object-fit: cover;
     user-select: none;
     -webkit-user-drag: none;
}






/* MEDIA QUERY */
/* MEDIA QUERY */
/* MEDIA QUERY */
/* MEDIA QUERY */
/* MEDIA QUERY */


@media (max-width: 800px) {
    .rev-link {
        width: 60%;
    }

    .rev-scroll {
        height: 160px;
    }

    .rev-img {
        width: fit-content;
        object-fit: contain;
    }
}

@media (max-width: 550px) {
    .rev-link {
        font-size: 11pt;
    }

    .rev-h1 {
        font-size: 24pt;
    }
}

@media (max-width: 330px) {
    .rev-link {
        width: 70%;
        font-size: 10pt;
    }

    .rev-h1 {
        font-size: 20pt;
    }
}