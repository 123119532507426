.galleryhome {
    background-color: #da0b64; color: white;
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: flex-start;
    padding-inline: 10%; padding-block: 80px;
}

.gh-link {
    text-decoration: none;
    background-color: white;
    color: black;
    box-shadow: white 0 0 8px 2px;

    width: 30%;
    padding: 16px 32px;
    margin-top: 30px;
    text-align: center;
    border-radius: 30px;

    user-select: none;
    transition: all 0.3s ease-in-out;
}

.gh-link:hover {
    opacity: 0.85;
}


.gh-scroll {
    height: 280px; width: 100%; background-color: aliceblue;
    overflow: hidden;
    display: flex; flex-direction: row; gap: 5px;
    border-radius: 10px;
    box-shadow: silver 0 0 12px 4px;
}
.gh-img {
    height: 100%;
    width: 28%; object-fit: cover;
}



/* MEDIA QUERY */
/* MEDIA QUERY */
/* MEDIA QUERY */
/* MEDIA QUERY */
/* MEDIA QUERY */


@media (max-width: 800px) {
    .gh-link {
        width: 60%;
    }

    .gh-scroll {
        height: 200px;
    }

    .gh-img {
        width: 70%;
    }
}

@media (max-width: 550px) {
    .gh-link {
        font-size: 11pt;
    }

    .gh-h1 {
        font-size: 24pt;
    }
}

@media (max-width: 330px) {
    .gh-link {
        width: 70%;
        font-size: 10pt;
    }

    .gh-h1 {
        font-size: 20pt;
    }
}