.houseinfo {
    margin-block: 160px;
    padding-inline: 10%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.house-data {
    width: 60%;
    background-color: rgb(39, 39, 39);
    color: white;
    padding: 32px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.house-data p {
    margin-bottom: 40px;
    font-size: 11pt;
    border-bottom: white 2px dashed;
    padding-bottom: 16px;
}

.house-data h1, .house-data h2 {
    color: goldenrod
}

.house-data h1:nth-of-type(2) {
    color: gold
}



/* MEDIA QUERY */
/* MEDIA QUERY */
/* MEDIA QUERY */
/* MEDIA QUERY */
/* MEDIA QUERY */


@media (max-width: 800px) {
    .house-data {
        width: 70%;
    }
}

@media (max-width: 550px) {
    .house-data {
        width: 80%;
    }

    .house-data p {
        margin-bottom: 40px;
        font-size: 10pt;
    }

    .house-data h1  {
font-size: 26pt;    }
.house-data h2 {
    font-size: 18pt;    }
}


    

@media (max-width: 330px) {
    .house-data p {
        margin-bottom: 40px;
        font-size: 9pt;
    }

    .house-data h1  {
        font-size: 22pt;    }
        .house-data h2 {
            font-size: 14pt;    }
}