.error {
    position: fixed; top: 0; left: 0; width: 100%; height: 100vh;
    display: flex;  place-items: center;
}

.error-h1 {
    text-align: center;
    margin-inline: 20%;
    color: #da0b64;
}

.error-link {
    text-decoration: none;
    color: black;
    transition: all 0.3s ease-in-out;
}

.error-link:hover {
    opacity: 0.5;
}




@media (max-width: 550px) {
    .error-h1 {
        font-size: 18pt;
    }
}