.basicinfo {
    margin-block: 160px;
    display: flex;
    flex-direction: row;  justify-content: center; align-items: center;
    width: 100%;
    
}

.basic1 {
    flex: 1;
    padding-inline: 10%;
    display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start;

}

.basic2 {
    flex: 1;
    overflow: hidden;
    width: 100%;
    height: 500px; object-fit: cover;
    border-radius: 30px;

    margin-right: 10%;
    border: #da0b64 solid 6px;
    box-shadow: gray 0 0 12px 4px;

}


.tajana {
    user-select: none; -webkit-user-drag: none;
     width: 90%
}

.tajana-desc {
    margin-block: 30px; 
    font-style: italic;
    font-size: 10pt;
text-align: justify;}

.basic-line {
    width: 80%;
    background-color: silver;
    height: 2px; 
    margin-bottom: 30px;
}



.basic-more {
    display: flex;
    flex-direction: column;
    line-height: 1;
    font-size: 11pt;
    color: rgb(70, 70, 70);
    margin-bottom: 30px;
}

.basic-more b {
    color: black;
}

.basic-btn {
    text-decoration: none;
    color: white;
    background-color: #da0b64;
    width: 60%;
    padding: 16px 32px;
    margin-top: 20px;
    text-align: center;
    border-radius: 30px;
    box-shadow: #da0b64 0 0 8px 2px;

}





/*  MEDIA QUERY  */
/*  MEDIA QUERY  */
/*  MEDIA QUERY  */
/*  MEDIA QUERY  */
/*  MEDIA QUERY  */

@media (max-width:875px) {
    .basicinfo {
        flex-direction: column;
        gap: 120px
    }

    .basic2 {
        overflow: hidden;
        width: 80%;
        height: 300px; object-fit: cover;
        border-radius: 30px;
margin:auto;
        border: #da0b64 solid 6px;
        box-shadow: gray 0 0 12px 4px;
    
    }
}




@media (max-width: 330px) {
    .basic2 {
        width: 90%;
    }
}
