.contact {
    display: flex;
    flex-direction: column;
}

.price-info {
    padding-inline: 10%; padding-block: 60px;
    background-color: #da0b64;
    color: white;
    margin-bottom: 160px;
    box-shadow: 0 0 16px 4px rgb(112, 112, 112);
    font-weight: bold;
    text-align: justify;

    display: flex; flex-direction: row;
    gap: 80px;
    place-items: center;
    line-height: 1.5;
}

.euro {
    transform: scale(3); background-color: white; border-radius: 50%; color: #da0b64; padding: 5px;
}

.eurolink {
    text-decoration: none;
    color: yellow;
    letter-spacing: 5px;
    margin-left: 10px;
}



@media (max-width: 725px) {
    .price-info {
        flex-direction: column;
    }

    .euro {
        margin-top: 50px;
    }
}