.gallery {
    display: flex;
    flex-direction: column;
}

.hidden .add {
    display: none;
}


.carousel {
    margin-inline: 10%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-bottom: 120px;
}

.carousel-img {
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    border: white solid 2px;
    cursor: pointer;
    transition: all 0.3s ease-out;
    user-select: none; -webkit-user-drag: none;

}

.carousel-img:hover {
    transform: scale(0.95);
    opacity: 0.8;
z-index: 1;
}

.spread {
    position: fixed; top: 0; left: 10%; height: 100%; width: 82%; margin: auto; opacity: 1;
    box-shadow: 0 0 100px 5px black;
    border: none;
    
    z-index: 70;
}

.spread:hover {
    opacity: 1;
    transform: none;
}


.touch {
    background-color: #da0b64;
    color: white;
    padding: 16px 32px;
    border-radius: 30px;
    box-shadow: 0 0 12px 4px #da0b64;
    margin-inline: 10%; margin-bottom: 120px;
    width: 30%;
    text-align: center;
    font-weight: bold;
    transition: all 0.3s ease-in-out;
    text-decoration: none;

    text-decoration: none; 

}



.touch:hover  {
opacity: 0.75;}



@media (max-width: 725px) {
    .gallery .navigation2 {
        display: flex;
    }

    .carousel {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .touch {
        width: 50%;
    }

    .spread {
        width: 94%;
        padding: 0;
        left:3%;
    }
}


@media (max-width: 475px) {
   

    .carousel {
        grid-template-columns: 1fr 1fr;
    }
}


@media (max-width: 330px) {
   

    .carousel {
        grid-template-columns: 1fr;
    }

    .touch {
        width: 70%;
        padding: 8px 16px;
    }
}